import {IQueryAllResult} from "../models/query-all-result.model";
import {ISetting} from "../models/setting.model";
import {getNodes} from "./get-nodes";


export default function getShopSettings(settings: IQueryAllResult<ISetting>) {
    const newSettings = getNodes(settings);

    return {
        perPage: newSettings.find((setting) => setting.name === 'productListing.itemsPerPage')
            ?.value,
        perRow: newSettings.find((setting) => setting.name === 'productListing.itemsPerRow')?.value,
    };
}