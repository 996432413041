import React from 'react';
import { Formik } from 'formik';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';
import { Link } from 'gatsby';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    grid,
    container,
    header,
    title,
    headerButton,
    headerLink,
    image,
    imageRatio,
    filtersBox,
    sortBox,
    list,
    card,
    paginationBox,
    listBox,
    statusBox,
    counter,
    loading,
    filterTagsDesktop,
    filterTagsMobile,
    initialLoader,
    initial,
} from './product-listing.module.scss';
import { ISection } from '../../models/section.model';
import { IProduct } from '../../models/product.model';
import { TStatus } from '../../models/status.model';
import { IPagination } from '../../models/pagination.model';
import { IFilter, IFilters, ISelectedFilter } from '../../models/filter.model';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import Pagination, { IPaginationProps } from '../molecules/pagination';
import Loader from '../atoms/loader';
import ProductCard from '../molecules/product-card';
import Filters from '../organisms/filters';
import ListSort from '../organisms/list-sort';
import HandleFormikChange from '../hoc/handle-formik-change';
import SelectedFilters from '../organisms/selected-filters';
import Button from '../atoms/button';

interface IProductListingSection extends ISection {
    items: {
        products: IProduct[];
    };
    settings: {
        status: TStatus;
        isInitialLoading: boolean;
        filters?: IFilters;
        sort?: IFilter;
        values?: Record<string, string | string[]> | null;
        paginationPaths: string[];
        pagination?: IPagination;
        selectedFilters?: ISelectedFilter[];
        onChange?(values: Record<string, string | string[]>): void;
        perRow: number;
    };
}

interface IProductListingProps {
    className?: string;
    section: IProductListingSection;
    TitleTag?: React.ElementType;
    paginationProps?: IPaginationProps;
}

const ProductListing: React.FC<IProductListingProps> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const t = useTranslations('ProductListing');
    const { type } = usePageContext();
    const {
        sectionId,
        css,
        style,
        content: { texts, media, buttons },
        items: { products },
        settings: {
            status,
            isInitialLoading,
            paginationPaths,
            pagination,
            onChange,
            filters,
            sort,
            values,
            selectedFilters,
            perRow,
        },
    } = section;
    const productCount = pagination?.totalCount || 0;
    const titleContent = type === 'search-product-listing' ? getSearchTitle(texts[0]) : texts[0];
    const hasButton = buttons[0]?.text && buttons[0]?.url;
    const hasImage = !!media[0];
    const columnCount = {
        '--column-count': perRow <= 2 ? 2 : perRow >= 5 ? 5 : perRow,
    } as React.CSSProperties;
    return (
        <Section
            sectionId={sectionId}
            className={className}
            classes={{
                container: grid,
            }}
            css={css}
            style={style}
        >
            <div className={`${container} ${status === 'loading' ? loading : ''}`}>
                <div className={header}>
                    <TitleTag className={title}>{titleContent}</TitleTag>
                    {(hasButton || hasImage) && (
                        <Link to={buttons[0]?.url || '/'} className={headerLink}>
                            {hasButton && (
                                <Button as="element" className={headerButton}>
                                    {buttons[0]?.text}
                                </Button>
                            )}
                            <Image
                                className={image}
                                media={media}
                                preserveSpace={false}
                                ratioClass={imageRatio}
                                objectFit="contain"
                            />
                        </Link>
                    )}
                </div>
                {isInitialLoading && status === 'error' && (
                    <div className={`${statusBox} ${initial}`}>
                        <p>{t.error}</p>
                    </div>
                )}
                {isInitialLoading && status !== 'error' && <Loader className={initialLoader} />}
                {!isInitialLoading && (
                    <Formik
                        onSubmit={() => {}}
                        initialValues={values || {}}
                        enableReinitialize={true}
                    >
                        {() => (
                            <>
                                <div className={listBox}>
                                    {status === 'error' && (
                                        <div className={statusBox}>
                                            <p>{t.error}</p>
                                        </div>
                                    )}
                                    {status === 'success' && products.length === 0 && (
                                        <div className={statusBox}>
                                            <p>{t.notFound}</p>
                                        </div>
                                    )}
                                    {status === 'loading' && products.length === 0 && (
                                        <div className={statusBox}>
                                            <Loader />
                                        </div>
                                    )}
                                    {(status === 'success' || status === 'loading') &&
                                        products.length > 0 && (
                                            <ul className={list} style={columnCount}>
                                                {products.map((product) => {
                                                    return (
                                                        <li
                                                            key={`post-card-item-${product.productId}`}
                                                        >
                                                            <ProductCard
                                                                NameTag="h3"
                                                                product={product}
                                                                className={card}
                                                            />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    <Pagination
                                        className={paginationBox}
                                        paginationPaths={paginationPaths}
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                )}
            </div>
        </Section>
    );
};

function getSearchTitle(title: string | null): React.ReactNode {
    if (!title) return title;
    const separationIndex = title.indexOf('"');
    if (separationIndex < 0) return title;
    const titleStart = title.substring(0, separationIndex);
    const search = title.substring(separationIndex);
    return (
        <>
            {titleStart}
            <span>{search}</span>
        </>
    );
}

export default ProductListing;
