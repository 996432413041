import { ICartItemMutation, ICartMutation } from '../models/cart.model';
import { IProductVariant } from '../models/product-variant.model';

export function getCartMutation(
    variantId: IProductVariant['variantId'],
    action: ICartItemMutation['action'],
    quantity = 1
): ICartMutation {
    return { items: [{ variantId, action, quantity }] };
}
