import { useEffect } from 'react';

import { useDispatch, useSelector } from '../redux/hooks';

import { ICartItem, ICartMutation } from '../models/cart.model';
import { useGetCartQuery, useCreateCartMutation, useUpdateCartMutation } from '../redux/api/cart';
import { getCartMutation } from '../utils/get-cart-mutation';
import useTranslations from './use-translations';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import { toast } from '../components/hoc/toast';
import { useUser } from './use-user';
import { selectCartState, setCartIsAfterMutation } from '../redux/slices/cart.slice';

export const useCart = () => {
    const t = useTranslations('RTKQueryApi');
    const dispatch = useDispatch();

    const userState = useUser();
    const cartState = useSelector(selectCartState);

    const cartId = cartState.cartId;
    const noCartId = !cartId;

    const getCartQuery = useGetCartQuery(cartId, { skip: noCartId });
    const [createCart, createCartMutation] = useCreateCartMutation();
    const [updateCart, updateCartMutation] = useUpdateCartMutation();

    const isDisabled =
        userState.get.isFetching ||
        getCartQuery.isFetching ||
        createCartMutation.isLoading ||
        updateCartMutation.isLoading;

    const items = cartState.data?.items || [];
    const count = items.reduce((acc, item) => acc + item.quantity, 0);
    const isEmpty = count === 0;

    const handleCartChange = (mutation: ICartMutation) => {
        if (isDisabled) return;

        if (cartId) {
            updateCart({ cartId, mutation });
            return;
        }

        createCart({ mutation, isGuestCart: !userState.isLoggedIn });
    };

    const handleCartItemQuantityChange = (item: ICartItem, newQuantity: number) => {
        const quantity = Math.abs(item.quantity - newQuantity);
        const action = item.quantity < newQuantity ? 'add' : 'delete';
        const mutation = getCartMutation(item.variantId, action, quantity);
        handleCartChange(mutation);
    };

    const handleRemoveCartItem = (item: ICartItem) => {
        const mutation = getCartMutation(item.variantId, 'delete', item.quantity);
        handleCartChange(mutation);
    };

    useEffect(() => {
        const createErrors = getRtkQueryErrors(createCartMutation.error, t.errors) || [];
        const updateErrors = getRtkQueryErrors(updateCartMutation.error, t.errors) || [];
        const mutationErrors = [...createErrors, ...updateErrors];

        if (!mutationErrors.length) return;

        mutationErrors.forEach((error) => {
            toast(error.content, { type: 'error' });
        });
    }, [createCartMutation.error, t.errors, updateCartMutation.error]);

    return {
        ...cartState,
        handleCartChange,
        handleCartItemQuantityChange,
        handleRemoveCartItem,
        createCartMutation,
        updateCartMutation,
        isDisabled,
        isEmpty,
        count,
        items,
        setIsAfterMutation: (arg: Parameters<typeof setCartIsAfterMutation>[0]) => {
            dispatch(setCartIsAfterMutation(arg));
        },
    };
};
